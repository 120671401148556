import { create } from 'zustand';
import { db } from '@/db';

type AppSettings = {
    maintenance: boolean;
    showAdventInFeed: boolean;
    showSnow: boolean;
    setMaintenance: (maintenance: boolean) => void;
    setShowAdventInFeed: (show: boolean) => void;
    setShowSnow: (show: boolean) => void;
};

export const useAppSettingsStore = create<AppSettings>()(set => ({
    maintenance: false,
    showAdventInFeed: false,
    showSnow: false,
    setMaintenance: (maintenance: boolean) => set({ maintenance }),
    setShowAdventInFeed: (show: boolean) => set({ showAdventInFeed: show }),
    setShowSnow: (show: boolean) => set({ showSnow: show })
}));
