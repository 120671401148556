'use client';
import React from 'react';
import PortalComponent from '../shared/PortalComponent';
import AkLogo from '../AkLogo';
import { BButton } from '../ui/button';
import { FaPlayCircle } from 'react-icons/fa';
import { motion } from 'motion/react';

const Step0 = ({ onNext }: { onNext: () => void }) => {
    return (
        <motion.div initial={{ opacity: 0, x: 500 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -500 }} transition={{ duration: 0.5 }}>
            <div className="flex w-full max-w-3xl flex-col items-center gap-4">
                <div className="">
                    <AkLogo size={250} />
                </div>
                <h1 className="text-2xl font-bold">Vítej na naší AK GIRLS platformě!</h1>
                <p className="w-full max-w-xl text-justify">
                    Jsi zatím trochu ztracená a nevíš, jak začít? Nemusíš se ničeho bát! Připravili jsme pro tebe podrobný návod, který ti krok za krokem ukáže, jak nastavit tvůj
                    profil, efektivně tvořit obsah a stát se úspěšnou AK GIRL na naší platformě! 🚀
                </p>
                <BButton size="lg" onClick={onNext} className="flex items-center gap-2">
                    <FaPlayCircle />
                    Jdeme na to!
                </BButton>
            </div>
        </motion.div>
    );
};

export default Step0;
