'use client';
import React, { useEffect, useRef, useState } from 'react';
import { FaCheck, FaChevronCircleDown, FaChevronCircleUp } from 'react-icons/fa';
import { motion } from 'motion/react';
import { BButton } from '../ui/button';
import { useCreatorOnboarding } from '@/stores/creator-onboarding.store';
import { useMutation, useQuery } from '@tanstack/react-query';
import { checkCreatorOnboardingCondition, getContentLength, increaseCreatorOnboardingStep } from '@/actions/creator.actions';
import { useToast } from '../ui/use-toast';

const CONDITION_ASSETS = 6;

const Step9 = () => {
    const { toast } = useToast();
    const { nextStep } = useCreatorOnboarding();

    const { data: contentLength, isLoading } = useQuery({
        queryKey: ['contentLength'],
        queryFn: () => getContentLength()
    });

    const [highlightedElement, setHighlightedElement] = useState<HTMLElement | null>(null);
    useEffect(() => {
        setHighlightedElement(document.getElementById('creator-uploadButton') as HTMLElement);
    }, [contentLength]);

    const { mutate: checkCondition } = useMutation({
        mutationFn: () => checkCreatorOnboardingCondition(CONDITION_ASSETS),
        onError: () => {
            toast({
                title: 'Podmínka není splněna',
                description: `Nahráj alespoň ${CONDITION_ASSETS}x obsah a zkuste to znovu.`,
                variant: 'destructive'
            });
        },
        onSuccess: () => {
            nextStep();
        }
    });

    const [expanded, setExpanded] = useState(false);
    return (
        <>
            {highlightedElement !== null && (contentLength === undefined || contentLength < 1) && (
                <motion.div
                    key={highlightedElement.id}
                    initial={{ opacity: 0 }}
                    animate={{
                        opacity: 1,
                        top: highlightedElement.getBoundingClientRect().top,
                        left: highlightedElement.getBoundingClientRect().left,
                        width: highlightedElement.offsetWidth,
                        height: highlightedElement.offsetHeight
                    }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    onClick={() => {
                        highlightedElement.click();
                    }}
                    className="fixed z-ultr rounded-md border border-white shadow-[0_0_0_9999px_rgba(0,0,0,0.9)]"
                />
            )}
            <div className="sticky top-16 z-50 w-full bg-primary px-3 py-2 text-white" onClick={() => setExpanded(prev => !prev)}>
                <span className="flex items-center justify-between">
                    <h5 className="font-bold">#9 Nahraj svůj první obsah </h5>
                    <motion.div
                        animate={{
                            rotate: expanded ? 180 : 0,
                            opacity: [0.3, 1, 0.3]
                        }}
                        transition={{
                            opacity: {
                                repeat: Infinity,
                                duration: 1,
                                ease: 'easeOut'
                            }
                        }}>
                        <FaChevronCircleDown />
                    </motion.div>
                </span>
                <span>
                    <motion.div
                        initial={{ height: 0 }}
                        animate={{
                            height: expanded ? 'auto' : 0
                        }}
                        className="space-y-3 overflow-hidden">
                        <div className="my-3 h-px bg-secondary" />
                        <p>
                            Přidej prvních 6 fotek nebo videí, které nejlépe zvýrazní tvůj styl, zájmy a jedinečnost. Vyber obsah, který zaujme sledující na první pohled a zároveň
                            ukáže, co mohou od tebe očekávat.
                        </p>
                        <p>
                            Může to být kombinace profesionálně nafocených snímků i momentek, které zachycují tvou přirozenost. Dobré osvětlení a originální nápady dokážou udělat
                            obrovský dojem.
                        </p>
                        <BButton variant="secondary" className="flex w-full items-center gap-2" onClick={() => checkCondition()}>
                            <FaCheck />
                            Hotovo
                        </BButton>
                    </motion.div>
                </span>
            </div>
        </>
    );
};

export default Step9;
