import { useState, useCallback } from 'react';
import { useUploadStore } from '@/stores/upload-status.store';
import { useQueryClient } from '@tanstack/react-query';

interface UploadItem {
    file: File;
    data: {
        text: string;
        exclusive: boolean;
        colabWith?: string | null;
        exclusivePrice?: number;
    };
}

export const useUploadQueue = () => {
    const queryClient = useQueryClient();
    const [queue, setQueue] = useState<UploadItem[]>([]);
    const { addToQueue, removeFromQueue, setIsUploading, setUploadType } = useUploadStore();

    const addToUploadQueue = useCallback(
        (item: UploadItem) => {
            setQueue(prevQueue => [...prevQueue, item]);
            addToQueue();
        },
        [addToQueue]
    );

    const uploadNext = useCallback(async () => {
        if (queue.length === 0) {
            setIsUploading(false);
            return;
        }

        const item = queue[0];
        setIsUploading(true);
        setUploadType(item.file.type.startsWith('image') ? 'image' : 'video');

        const formData = new FormData();
        formData.append('file', item.file);
        formData.append('text', item.data.text || '');
        formData.append('colabWith', item.data.colabWith || '');
        formData.append('exclusivePrice', item.data.exclusivePrice?.toString() || '');
        formData.append('exclusive', item.data.exclusive.toString());

        try {
            const response = await fetch('/api/upload-content', {
                method: 'POST',
                body: formData
            });

            if (!response.ok) {
                throw new Error('Upload failed');
            }

            removeFromQueue();
            setQueue(prevQueue => prevQueue.slice(1));
            queryClient.invalidateQueries({
                queryKey: ['content'],
                exact: false
            });

            if (queue.length > 1) {
                uploadNext();
            } else {
                setIsUploading(false);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            setIsUploading(false);
        }
    }, [queue, removeFromQueue, setIsUploading, setUploadType, queryClient]);

    return { addToUploadQueue, uploadNext };
};
