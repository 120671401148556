'use client';
import { useCreatorOnboarding } from '@/stores/creator-onboarding.store';
import { motion } from 'motion/react';
import { useState } from 'react';
import { FaArrowRight, FaChevronCircleDown } from 'react-icons/fa';
import { BButton } from '../ui/button';
import { increaseCreatorOnboardingStep } from '@/actions/creator.actions';
import { useMutation } from '@tanstack/react-query';

const Step15 = () => {
    const { mutate: increaseOnboardingStep } = useMutation({
        mutationFn: () => increaseCreatorOnboardingStep(),
        onSuccess: () => {
            nextStep();
        }
    });
    const [expanded, setExpanded] = useState(false);
    const { nextStep } = useCreatorOnboarding();
    return (
        <div className="sticky top-16 z-50 w-full bg-primary px-3 py-2 text-white" onClick={() => setExpanded(prev => !prev)}>
            <span className="flex items-center justify-between">
                <h5 className="font-bold">#15 AK GIRLS tvůj VIP KLUB</h5>
                <motion.div
                    animate={{
                        rotate: expanded ? 180 : 0,
                        opacity: [0.3, 1, 0.3]
                    }}
                    transition={{
                        opacity: {
                            repeat: Infinity,
                            duration: 1,
                            ease: 'easeOut'
                        }
                    }}>
                    <FaChevronCircleDown />
                </motion.div>
            </span>
            <span>
                <motion.div
                    initial={{ height: 0 }}
                    animate={{
                        height: expanded ? 'auto' : 0
                    }}
                    className="space-y-3 overflow-hidden">
                    <div className="my-3 h-px bg-secondary" />
                    <p>
                        Tvůj AK GIRLS profil je jako exkluzivní VIP klub – místo, kam nemůže každý, a je vyhrazeno pouze pro tvoje nejvěrnější fanoušky. I když vstup není zdarma -
                        fanoušci si rádi zaplatí, pokud jim nabídneš něco opravdu výjimečného, co nikde jinde nenajdou. Pro ty, kteří si zaplatit nechtějí, je tu stále možnost tě
                        sledovat zdarma na ostatních sociálních sítích.
                    </p>
                    <BButton variant="secondary" className="flex w-full items-center gap-2" onClick={() => increaseOnboardingStep()}>
                        Další
                        <FaArrowRight />
                    </BButton>
                </motion.div>
            </span>
        </div>
    );
};

export default Step15;
