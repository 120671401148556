import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.62.7_react@19.0.0/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.62.7_react@19.0.0/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@tanstack+react-query@5.62.7_react@19.0.0/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.62.7_react@19.0.0/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.62.7_react@19.0.0/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.62.7_react@19.0.0/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.62.7_react@19.0.0/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.62.7_react@19.0.0/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.62.7_react@19.0.0/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.62.7_react@19.0.0/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.62.7_react@19.0.0/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.62.7_react@19.0.0/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.62.7_react@19.0.0/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.26.1_next@15.1.0_@babel+core@7.26.0_react-dom@19.0.0_react@19.0.0__react@19.0.0__react@19.0.0/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.26.1_next@15.1.0_@babel+core@7.26.0_react-dom@19.0.0_react@19.0.0__react@19.0.0__react@19.0.0/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/app/node_modules/.pnpm/next-themes@0.4.4_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next-themes/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/.pnpm/next@15.1.0_@babel+core@7.26.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.1.0_@babel+core@7.26.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.1.0_@babel+core@7.26.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/[locale]/(private)/InitState.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/[locale]/(private)/Snow.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/AkLogo.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/bottomNav/BottomNav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/creator-onboarding/CreatorOnboarding.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/RealtimeService/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/shared/CoinsCount.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/shared/NotificationEnable.banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/shared/PwaInstall.banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UploadStatus"] */ "/app/src/components/shared/UploadStatus.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Popover","PopoverTrigger","PopoverContent"] */ "/app/src/components/ui/popover.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Separator"] */ "/app/src/components/ui/separator.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/src/components/ui/sonner.tsx");
