'use client';
import React, { useEffect, useState } from 'react';
import { motion } from 'motion/react';
import { Separator } from '@radix-ui/react-select';
import { BButton } from '../ui/button';
import { useCreatorOnboarding } from '@/stores/creator-onboarding.store';
const Step7 = ({ onNext }: { onNext: () => void }) => {
    const [highlightedElement, setHighlightedElement] = useState<HTMLElement | null>(null);
    const { setMinimized } = useCreatorOnboarding();
    useEffect(() => {
        setHighlightedElement(document.getElementById('creatorProfile-editPrices') as HTMLElement);
    }, []);
    return (
        <div className="fixed inset-0 z-ultr flex items-center justify-center">
            {highlightedElement && (
                <motion.div
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{
                        opacity: 1,
                        scale: 1,
                        top: highlightedElement.getBoundingClientRect().top - 10,
                        left: highlightedElement.getBoundingClientRect().left - 10,
                        width: highlightedElement.offsetWidth + 20,
                        height: highlightedElement.offsetHeight + 20
                    }}
                    exit={{ opacity: 0, scale: 0.9 }}
                    transition={{ duration: 0.3 }}
                    onClick={() => {
                        setMinimized(true);
                        highlightedElement.click();
                    }}
                    className="absolute rounded-md border border-white shadow-[0_0_0_9999px_rgba(0,0,0,0.9)]"
                />
            )}
            <div className="z-10 flex w-full max-w-3xl flex-col items-center justify-center gap-4 px-10">
                <div
                    className="absolute"
                    style={{
                        top: highlightedElement ? highlightedElement.getBoundingClientRect().bottom + 25 : 0,
                        left: highlightedElement ? highlightedElement.getBoundingClientRect().left : 0
                    }}>
                    <div className="relative rounded-lg bg-popover p-4 text-popover-foreground shadow-md">
                        <div className="absolute -top-2 left-4 h-4 w-4 rotate-45 bg-popover" />
                        <h1 className="text-lg font-bold">#7 Nastav si ceny</h1>
                        <p className="text-sm">Tvůj profil by měl být odrazem tvé jedinečnosti. Nastav si dle svého uvážení ceny za předplatné (odběr) a za zprávy.</p>
                        <p className="mt-2 text-sm">
                            Začni s nižšími cenami, které přitáhnou nové sledující. Jakmile se tvoje komunita bude rozrůstat, můžeš ceny postupně zvyšovat, aby odpovídaly hodnotě a
                            kvalitě, kterou na svém profilu poskytuješ.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Step7;
