'use client';
import { useCreatorOnboarding } from '@/stores/creator-onboarding.store';
import { motion } from 'motion/react';
import { useEffect, useState } from 'react';
const Step8 = ({ onNext }: { onNext: () => void }) => {
    const [highlightedElement, setHighlightedElement] = useState<HTMLElement | null>(null);
    const { setMinimized } = useCreatorOnboarding();
    useEffect(() => {
        setHighlightedElement(document.getElementById('creatorProfile-editProfile') as HTMLElement);
    }, []);
    return (
        <div className="fixed inset-0 z-ultr flex items-center justify-center">
            {highlightedElement && (
                <motion.div
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{
                        opacity: 1,
                        scale: 1,
                        top: highlightedElement.getBoundingClientRect().top - 10,
                        left: highlightedElement.getBoundingClientRect().left - 10,
                        width: highlightedElement.offsetWidth + 20,
                        height: highlightedElement.offsetHeight + 20
                    }}
                    exit={{ opacity: 0, scale: 0.9 }}
                    transition={{ duration: 0.3 }}
                    onClick={() => {
                        setMinimized(true);
                        highlightedElement.click();
                    }}
                    className="absolute rounded-md border border-white shadow-[0_0_0_9999px_rgba(0,0,0,0.9)]"
                />
            )}
            <div className="z-10 flex w-full max-w-3xl flex-col items-center justify-center gap-4 px-10">
                <div
                    className="absolute"
                    style={{
                        top: highlightedElement ? highlightedElement.getBoundingClientRect().bottom + 25 : 0,
                        right: highlightedElement ? highlightedElement.getBoundingClientRect().left - highlightedElement.offsetWidth : 0
                    }}>
                    <div className="relative rounded-lg bg-popover p-4 text-popover-foreground shadow-md">
                        <div className="absolute -top-2 right-4 h-4 w-4 rotate-45 bg-popover" />
                        <h1 className="text-lg font-bold">#8 Uprav si profil</h1>
                        <p className="text-sm">
                            Nahraj kvalitní profilovou fotku a napiš poutavý popisek, který tě vystihuje. Můžeš zmínit své zájmy, co tě motivuje, nebo proč ses rozhodla začít na
                            této platformě. Tvůj profil je první věc, kterou si lidé na AK GIRLS prohlédnou, tak se ujisti, že se představuješ v tom nejlepším světle.
                        </p>
                        <p className="text-sm">Zvol si také kategorii, která nejlépe vystihuje a odráží tvoji osobnost a styl.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Step8;
