'use client';

import { useTheme } from 'next-themes';
import { Toaster as Sonner } from 'sonner';

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
    const { theme } = useTheme();

    return (
        <Sonner
            theme={theme as ToasterProps['theme']}
            className="toaster group"
            toastOptions={{
                classNames: {
                    toast: 'group toast group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg',
                    // success: 'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
                    // error: 'group-[.toast]:bg-destructive group-[.toast]:text-destructive-foreground',
                    // warning: 'group-[.toast]:bg-amber-500 group-[.toast]:text-primary-text group-[.toast]:text-amber-200',
                    // info: 'group-[.toast]:bg-sky-500 group-[.toast]:text-primary-text group-[.toast]:text-sky-200',
                    description: 'group-[.toast]:text-muted-foreground',
                    actionButton: 'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
                    cancelButton: 'group-[.toast]:bg-muted group-[.toast]:text-muted-foreground'
                }
            }}
            {...props}
        />
    );
};

export { Toaster };
