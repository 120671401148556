'use client';
import { getSettings } from '@/app/admin/actions/admin.actions';
import { User } from '@/db/schema';
import { useAppSettingsStore } from '@/stores/appSettings.store';
import { useCoins } from '@/stores/coins';
import { useUser } from '@/stores/user.store';
import { useQuery } from '@tanstack/react-query';
import React, { PropsWithChildren, useEffect } from 'react';

const InitState: React.FC<{ user: User } & PropsWithChildren> = ({ children, user }) => {
    const { setCoins } = useCoins();
    const setUserInfo = useUser(state => state.setUserInfo);
    const { data: appSettings } = useQuery({
        queryKey: ['appSettings'],
        queryFn: () => getSettings(),
        staleTime: Infinity
    });
    const { setShowAdventInFeed, setShowSnow } = useAppSettingsStore();
    useEffect(() => {
        setCoins(user.coins);
        setUserInfo(user);
        if (appSettings) {
            setShowAdventInFeed(appSettings.showAdventOnFeed);
            setShowSnow(appSettings.snow);
        }
    }, [appSettings, user]);
    return <>{children}</>;
};

export default InitState;
