'use client';
import { subscribeToPushNotifications } from '@/lib/notifications';
import { AnimatePresence, motion } from 'motion/react';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import { FaBell, FaChevronDown, FaPlayCircle } from 'react-icons/fa';
import { IoShareOutline } from 'react-icons/io5';
import { MdMoreVert } from 'react-icons/md';
import AkLogo from '../AkLogo';
import { BButton } from '../ui/button';
import { toast } from '../ui/use-toast';
import { useCreatorOnboarding } from '@/stores/creator-onboarding.store';
import { increaseCreatorOnboardingStep } from '@/actions/creator.actions';
import { useMutation } from '@tanstack/react-query';

const InstallAndAllowNotificationsApp = () => {
    const [openedInstallGuide, setOpenedInstallGuide] = useState(false);
    const [platform, setPlatform] = useState<'iOS' | 'Android' | 'Desktop' | 'Unknown'>('Unknown');
    const [isPWA, setIsPWA] = useState(false);

    useEffect(() => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
        const isFullscreen = window.matchMedia('(display-mode: fullscreen)').matches;
        const isIOSPWA = 'standalone' in window.navigator && (window.navigator as any).standalone;
        const isPWA = isStandalone || isFullscreen || isIOSPWA;
        setIsPWA(isPWA);
        if (/iphone|ipad|ipod/.test(userAgent)) {
            setPlatform('iOS');
        } else if (/android/.test(userAgent)) {
            setPlatform('Android');
        } else if (/win|mac|linux/.test(userAgent)) {
            setPlatform('Desktop');
        }
    }, []);

    return (
        <>
            <AnimatePresence>
                {!isPWA && platform !== 'Desktop' && platform !== 'Unknown' && openedInstallGuide && <InstallApp platform={platform} />}
                {isPWA && <EnableNotifications />}
            </AnimatePresence>
            {!isPWA && (
                <motion.div initial={{ opacity: 0, x: 500 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -500 }} transition={{ duration: 0.5 }}>
                    <div className="flex w-full max-w-3xl flex-col items-center gap-4">
                        <div className="">
                            <AkLogo size={250} />
                        </div>
                        <h1 className="text-2xl font-bold">Nainstaluj si aplikaci</h1>

                        <BButton
                            size="lg"
                            onClick={() => {
                                setOpenedInstallGuide(true);
                            }}
                            className="flex items-center gap-2">
                            Jak na to?
                            <FaPlayCircle />
                        </BButton>
                    </div>
                </motion.div>
            )}
        </>
    );
};

const InstallApp = ({ platform }: { platform: 'iOS' | 'Android' }) => {
    return (
        <motion.div
            className="fixed inset-0 z-ultr flex items-center justify-center overflow-y-auto bg-black/70 backdrop-blur-lg"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}>
            <div className="max-h-[90svh] overflow-y-auto rounded bg-popover p-4">
                {platform === 'iOS' && (
                    <div className="flex h-full flex-col items-center justify-center gap-5">
                        <div>
                            <h5 className="text-xl font-bold">Jak nainstalovat AkGirls.cz?</h5>
                            <p className="text-center">1. Otevři akgirls.cz v Safari</p>
                        </div>
                        <FaChevronDown />
                        <div className="flex flex-col items-center gap-2">
                            <span className="flex items-center gap-2">
                                <span>2. Klikni na tlačítko sdílet</span>
                                <span className="rounded border-2 p-1">
                                    <IoShareOutline size={32} />
                                </span>
                            </span>
                        </div>
                        <img src="/iosTut/step1.png" alt="step1" />
                        <div className="flex flex-col items-center gap-2">
                            <FaChevronDown />
                            <span>3. Klikni na tlačítko Přidat na plochu</span>
                        </div>
                        <img src="/iosTut/step2.png" alt="step2" />
                        <div className="flex flex-col items-center gap-2">
                            <FaChevronDown />
                            <span>4. Klikni na tlačítko Přidat</span>
                        </div>
                        <img src="/iosTut/step3.png" alt="step3" />
                        <FaChevronDown />
                        <span>5. Nyní se přihlaš přes aplikaci AkGirls.cz</span>
                    </div>
                )}
                {platform === 'Android' && (
                    <div className="flex h-full flex-col items-center justify-center gap-5">
                        <div>
                            <h5 className="text-xl font-bold">Jak nainstalovat AkGirls.cz?</h5>
                            <p>1. Otevři akgirls.cz v Google Chrome</p>
                        </div>
                        <FaChevronDown />
                        <div className="flex flex-col items-center gap-2">
                            <span className="flex items-center gap-2">
                                <span>2. Klikni na tlačítko možnosti</span>
                                <span className="rounded border-2 p-1">
                                    <MdMoreVert size={32} />
                                </span>
                            </span>
                        </div>
                        <img src="/androidTut/step1.png" alt="step1" />
                        <div className="flex flex-col items-center gap-2">
                            <FaChevronDown />
                            <span>3. Klikni na tlačítko Přidat na plochu</span>
                        </div>
                        <img src="/androidTut/step2.png" alt="step2" />
                        <div className="flex flex-col items-center gap-2">
                            <FaChevronDown />
                            <span>4. Klikni na tlačítko instalovat</span>
                        </div>
                        <img src="/androidTut/step3.png" alt="step3" />
                        <FaChevronDown />
                        <span>5. Nyní se přihlaš přes aplikaci AkGirls.cz</span>
                    </div>
                )}
            </div>
        </motion.div>
    );
};

const EnableNotifications = () => {
    const { setStep } = useCreatorOnboarding();
    const { mutate: increaseOnboardingStep } = useMutation({
        mutationFn: () => increaseCreatorOnboardingStep(),
        onSuccess: () => {
            setStep(3);
        }
    });
    const t = useTranslations();
    const handleNotification = async () => {
        const result = await Notification.requestPermission();
        const r = Notification.permission;
        if (result === 'granted' || r === 'granted') {
            await subscribeToPushNotifications();
            toast({
                title: t('toast.notifications.success.title'),
                description: t('toast.notifications.success.description'),
                variant: 'success'
            });
            increaseOnboardingStep();
        } else {
            toast({
                title: t('toast.notifications.error.title'),
                description: t('toast.notifications.error.description'),
                variant: 'info'
            });
            return;
        }
    };
    return (
        <div className="flex w-full max-w-3xl flex-col items-center justify-center gap-2">
            <BButton onClick={handleNotification} className="flex items-center gap-1">
                <FaBell />
                Zapnout notifikace
            </BButton>
        </div>
    );
};

export default InstallAndAllowNotificationsApp;
