import { create } from 'zustand';

type CreatorOnboardingStore = {
    step: number;
    minimized: boolean;
    onboardingCompleted: boolean;
    setStep: (step: number) => void;
    nextStep: () => void;
    completeOnboarding: () => void;
    setMinimized: (minimized: boolean) => void;
};

export const useCreatorOnboarding = create<CreatorOnboardingStore>(set => ({
    step: 0,
    onboardingCompleted: false,
    minimized: false,
    setStep: (step: number) => set({ step }),
    nextStep: () => set(state => ({ step: state.step + 1, minimized: false })),
    completeOnboarding: () => set({ onboardingCompleted: true }),
    setMinimized: (minimized: boolean) => set({ minimized })
}));
