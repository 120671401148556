'use client';
import { Button } from '@/components/ui/button';
import { Drawer, DrawerContent, DrawerHeader, DrawerTitle } from '@/components/ui/drawer';
import { zodResolver } from '@hookform/resolvers/zod';
import { loadStripe } from '@stripe/stripe-js';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from './ui/form';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './ui/select';
import { useTranslations } from 'next-intl';
import { LuCoins } from 'react-icons/lu';
import { useAppSettingsStore } from '@/stores/appSettings.store';

const FormSchema = z.object({
    priceId: z.string()
});

const TopUpDialog = ({ isOpen, onClose }: { isOpen: boolean; onClose: (open: boolean) => void }) => {
    const t = useTranslations();
    const winterMode = useAppSettingsStore(s => s.showSnow);
    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema)
    });

    const onTopup = async (data: z.infer<typeof FormSchema>) => {
        const stripe = await loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLIC!);
        if (!stripe) return;
        try {
            const res = await fetch('/api/checkout_sessions', {
                method: 'POST',
                body: JSON.stringify({ priceId: data.priceId })
            });
            if (res.ok) {
                stripe.redirectToCheckout({ sessionId: (await res.json()).result.id });
            }
            console.log(res);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Drawer open={isOpen} onOpenChange={open => onClose(open)}>
            <DrawerContent aria-describedby={undefined}>
                <DrawerHeader>
                    <DrawerTitle className="mb-4 mt-2 text-xl font-bold">{t('akCoins.recharge')}</DrawerTitle>
                </DrawerHeader>

                <div className="mx-auto flex w-full items-center justify-center gap-2">
                    <Form {...form}>
                        <form onSubmit={form.handleSubmit(onTopup)} className="mx-10 mb-10 w-full space-y-5">
                            <FormField
                                control={form.control}
                                name="priceId"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>{t('akCoins.sum')}</FormLabel>
                                        <Select onValueChange={field.onChange} defaultValue={field.value}>
                                            <FormControl>
                                                <SelectTrigger>
                                                    <SelectValue placeholder={t('akCoins.select')} />
                                                </SelectTrigger>
                                            </FormControl>
                                            <SelectContent>
                                                <SelectItem value={process.env.NEXT_PUBLIC_STRIPE_PRICE_250!}>
                                                    <span className="flex items-center gap-2">
                                                        <LuCoins />
                                                        <strong>250</strong>
                                                        AK COINS
                                                    </span>
                                                </SelectItem>
                                                <SelectItem value={process.env.NEXT_PUBLIC_STRIPE_PRICE_500!}>
                                                    <span className="flex items-center gap-2">
                                                        <LuCoins />
                                                        <strong>500</strong>
                                                        AK COINS
                                                    </span>
                                                </SelectItem>
                                                <SelectItem value={process.env.NEXT_PUBLIC_STRIPE_PRICE_1000!}>
                                                    <span className="flex items-center gap-2">
                                                        <LuCoins />
                                                        <strong>1000</strong>
                                                        AK COINS
                                                        {winterMode && (
                                                            <span className="text-red-500">
                                                                <p className="flex items-center gap-1 font-extrabold tracking-widest">
                                                                    (+ 100 <LuCoins />)
                                                                </p>
                                                            </span>
                                                        )}
                                                    </span>
                                                </SelectItem>
                                                <SelectItem value={process.env.NEXT_PUBLIC_STRIPE_PRICE_2000!}>
                                                    <span className="flex items-center gap-2">
                                                        <LuCoins />
                                                        <strong>2000</strong>
                                                        AK COINS
                                                        {winterMode && (
                                                            <span className="text-red-500">
                                                                <p className="flex items-center gap-1 font-extrabold tracking-widest">
                                                                    (+ 200 <LuCoins />)
                                                                </p>
                                                            </span>
                                                        )}
                                                    </span>
                                                </SelectItem>
                                                <SelectItem value={process.env.NEXT_PUBLIC_STRIPE_PRICE_5000!}>
                                                    <span className="flex items-center gap-2">
                                                        <LuCoins />
                                                        <strong>5000</strong>
                                                        AK COINS
                                                        {winterMode && (
                                                            <span className="text-red-500">
                                                                <p className="flex items-center gap-1 font-extrabold tracking-widest">
                                                                    (+ 500 <LuCoins />)
                                                                </p>
                                                            </span>
                                                        )}
                                                    </span>
                                                </SelectItem>
                                            </SelectContent>
                                        </Select>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <Button type="submit" className="w-full">
                                {t('akCoins.purchase')}
                            </Button>
                        </form>
                    </Form>
                </div>
            </DrawerContent>
        </Drawer>
    );
};

export default TopUpDialog;
