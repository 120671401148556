'use client';
import { getCreators, getCreatorsForColab } from '@/actions/girl.actions';
import AkLogo from '@/components/AkLogo';
import { BButton, bbuttonVariants } from '@/components/ui/button';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList, CommandSeparator } from '@/components/ui/command';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Switch } from '@/components/ui/switch';
import { Textarea } from '@/components/ui/textarea';
import { User } from '@/db/schema';
import { cn } from '@/lib/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useClickAway } from '@uidotdev/usehooks';
import { motion } from 'motion/react';
import { Check, ChevronsUpDown } from 'lucide-react';
import Image from 'next/image';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FiInfo, FiPlusCircle, FiSave, FiUpload, FiX } from 'react-icons/fi';
import { RiLock2Fill } from 'react-icons/ri';
import { z } from 'zod';
import { GirlContentFormSchema } from '../../../types/formSchema';
import { Link, useRouter } from '@/i18n/routing';
import { FaSnowflake } from 'react-icons/fa6';

type ContentInfoProps = {
    defaultValues?: z.infer<typeof GirlContentFormSchema>;
    onSubmit: (values: z.infer<typeof GirlContentFormSchema>) => void;
    creators: User[];
    onClose?: () => void;
    editMode?: boolean;
    priceError: string | null;
    setPriceError: Dispatch<SetStateAction<string | null>>;
    contentImg?: string;
    colabAccepted?: boolean;
};
const ContentInfoForm: React.FC<ContentInfoProps> = ({ onSubmit, defaultValues, creators, onClose, editMode, priceError, setPriceError, contentImg, colabAccepted = false }) => {
    const [creatorSearch, setCreatorSearch] = React.useState<string>('');
    const [creatorPopoverOpen, setCreatorPopoverOpen] = React.useState<boolean>(false);
    const searchRef = useClickAway<HTMLDivElement>(() => setCreatorPopoverOpen(false));
    const router = useRouter();

    const { data: users, isLoading: usersLoading } = useQuery({
        queryKey: ['users'],
        queryFn: () => getCreatorsForColab()
    });

    const {
        data,
        mutate: searchCreators,
        isPending,
        reset
    } = useMutation({
        mutationFn: async (search: string) => {
            return await getCreators();
        }
    });

    useEffect(() => {
        if (creatorSearch.length > 3) {
            searchCreators(creatorSearch.toLowerCase());
            setCreatorPopoverOpen(true);
        } else {
            reset();
            setCreatorPopoverOpen(false);
        }
    }, [creatorSearch]);

    const form = useForm<z.infer<typeof GirlContentFormSchema>>({
        resolver: zodResolver(GirlContentFormSchema),
        defaultValues: {
            exclusive: false,
            ...defaultValues
        }
    });
    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex h-full w-full flex-1 flex-col gap-2">
                <FormField
                    control={form.control}
                    name="exclusive"
                    render={({ field }) => (
                        <FormItem className="">
                            <motion.span className="relative flex flex-col gap-2 rounded-lg border border-primary p-4" layout layoutRoot>
                                <motion.div layout="position" className="flex w-full flex-row items-center justify-between">
                                    <div className="space-y-0.5">
                                        <FormLabel className="text-base">
                                            <Popover>
                                                <PopoverTrigger className="flex items-center gap-2" disabled={!form.getValues('exclusive') || contentImg == undefined}>
                                                    Exluzivní obsah
                                                    {form.getValues('exclusive') ? <FiInfo size={16} /> : null}
                                                </PopoverTrigger>
                                                <PopoverContent className="w-fit">
                                                    {contentImg ? (
                                                        <div className="relative">
                                                            Jak tento příspěvek uvidí tvoji odběratelé?
                                                            <div className="relative mt-2 aspect-square w-full overflow-hidden rounded-md">
                                                                <Image src={contentImg} alt="content image" fill objectFit="cover" />
                                                                <div className="absolute inset-0 flex flex-col items-center justify-center bg-black/20 text-white backdrop-blur-md">
                                                                    <div className="flex aspect-square flex-col items-center justify-center gap-1 rounded-full p-4">
                                                                        <div className="relative z-50 flex flex-col items-center justify-center">
                                                                            <RiLock2Fill className="size-6" />
                                                                            <span className="text-sm">{form.getValues('exclusivePrice')} Kč</span>
                                                                        </div>
                                                                        <AkLogo className="absolute z-0 size-48 translate-y-2" fill="hsla(0, 0%, 0%, .5 )" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <p>Nejprve narhaj obsah, potom se zde zobrazí náhled pro uživatele.</p>
                                                    )}
                                                </PopoverContent>
                                            </Popover>
                                        </FormLabel>
                                        <FormDescription className="mr-2">Bude tento příspěvek za příplatek?</FormDescription>
                                    </div>
                                    <FormControl>
                                        <Switch
                                            checked={field.value}
                                            onCheckedChange={checked => {
                                                field.onChange(checked);
                                                form.setValue('exclusivePrice', 0);
                                            }}
                                        />
                                    </FormControl>
                                </motion.div>
                                {form.getValues('exclusive') && (
                                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                                        <FormField
                                            control={form.control}
                                            name="exclusivePrice"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>Cena:</FormLabel>
                                                    <FormControl>
                                                        <Input
                                                            {...field}
                                                            type="number"
                                                            className="border border-primary"
                                                            onChange={e => {
                                                                setPriceError(null);
                                                                field.onChange(e.target.value !== '' ? Number(e.target.value) : '');
                                                            }}
                                                        />
                                                    </FormControl>
                                                    {priceError && <p className="text-red-500">{priceError}</p>}
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                    </motion.div>
                                )}
                            </motion.span>
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="text"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Text:</FormLabel>
                            <FormControl>
                                <Textarea {...field} placeholder="Text je viditelný pro všechny" />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="colabWith"
                    render={({ field }) => (
                        <FormItem className="relative">
                            <FormLabel>Spolupráce s:</FormLabel>
                            <Popover open={creatorPopoverOpen} onOpenChange={setCreatorPopoverOpen}>
                                <PopoverTrigger className="w-full">
                                    <FormControl>
                                        <div className={cn(bbuttonVariants({ variant: 'outline' }), 'w-full justify-between')}>
                                            {field.value ? <div className="flex items-center gap-1">{field.value}</div> : 'Vybrat uživatele...'}
                                            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                        </div>
                                    </FormControl>
                                </PopoverTrigger>
                                <PopoverContent className="!z-[999999999] w-dvw max-w-[500px] flex-1 flex-grow p-0">
                                    <Command>
                                        <CommandInput placeholder="Vyhledat uživatele..." />
                                        <CommandList>
                                            <CommandEmpty>Nanalezeno..</CommandEmpty>
                                            <CommandGroup>
                                                <CommandItem
                                                    value="akgirls"
                                                    onSelect={currentValue => {
                                                        field.onChange('akgirls');
                                                        setCreatorPopoverOpen(false);
                                                    }}>
                                                    <Check className={cn('mr-2 h-4 w-4', field.value === 'akgirls' ? 'opacity-100' : 'opacity-0')} />
                                                    <span className="flex items-center gap-1 text-xl">akgirls</span>
                                                </CommandItem>
                                                <CommandItem
                                                    value="akgirls18"
                                                    onSelect={currentValue => {
                                                        field.onChange('akgirls18');
                                                        setCreatorPopoverOpen(false);
                                                    }}>
                                                    <Check className={cn('mr-2 h-4 w-4', field.value === 'akgirls18' ? 'opacity-100' : 'opacity-0')} />
                                                    <span className="flex items-center gap-1 text-xl">akgirls18</span>
                                                </CommandItem>
                                            </CommandGroup>
                                            <CommandSeparator />
                                            <CommandGroup>
                                                {users &&
                                                    users
                                                        .sort((a, b) => a.username.localeCompare(b.username))
                                                        .filter(u => u.username !== 'akgirls' && u.username !== 'akgirls18')
                                                        .map(user => (
                                                            <CommandItem
                                                                key={user.id}
                                                                value={user.username}
                                                                onSelect={currentValue => {
                                                                    field.onChange(user.username === field.value ? null : user.username);
                                                                    setCreatorPopoverOpen(false);
                                                                }}>
                                                                <Check className={cn('mr-2 h-4 w-4', field.value === user.username ? 'opacity-100' : 'opacity-0')} />
                                                                <span className="flex items-center gap-1 text-xl">{user.username}</span>
                                                            </CommandItem>
                                                        ))}
                                            </CommandGroup>
                                        </CommandList>
                                    </Command>
                                </PopoverContent>
                            </Popover>
                        </FormItem>
                    )}
                />
                <div
                    className="fixed inset-x-0 bottom-0 grid grid-cols-2 gap-2 bg-background px-2 py-3"
                    style={{
                        boxShadow: '0px -3px 10px 0px hsl(0,0%,0%)'
                    }}>
                    {onClose && editMode && (
                        <BButton
                            variant="secondary"
                            className="flex w-full items-center gap-2"
                            onClick={e => {
                                e.preventDefault();
                                onClose();
                            }}>
                            <FiX />
                            Zrušit
                        </BButton>
                    )}
                    <BButton type="submit" className={cn('flex w-full items-center gap-2', { 'col-span-2': !editMode })}>
                        {editMode ? (
                            <>
                                <FiSave />
                                Uložit
                            </>
                        ) : (
                            <>
                                <FiUpload />
                                Nahrát
                            </>
                        )}
                    </BButton>
                </div>
            </form>
        </Form>
    );
};

export default ContentInfoForm;
