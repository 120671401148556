'use client';
import React, { useState } from 'react';
import { FaArrowRight, FaChevronCircleDown, FaChevronCircleUp } from 'react-icons/fa';
import { motion } from 'motion/react';
import { BButton } from '../ui/button';
import { FaCheck } from 'react-icons/fa6';
import { useLockBodyScroll } from '@uidotdev/usehooks';
import { useCreatorOnboarding } from '@/stores/creator-onboarding.store';
import { useMutation } from '@tanstack/react-query';
import { checkCreatorOnboardingCondition, increaseCreatorOnboardingStep } from '@/actions/creator.actions';
import { useToast } from '../ui/use-toast';

const CONDITION_ASSETS = 12;

const Step13 = () => {
    const { toast } = useToast();
    const { mutate: checkCondition } = useMutation({
        mutationFn: () => checkCreatorOnboardingCondition(CONDITION_ASSETS),
        onError: () => {
            toast({
                title: 'Podmínka není splněna',
                description: `Nahráj alespoň ${CONDITION_ASSETS}x obsah a zkuste to znovu.`,
                variant: 'destructive'
            });
        },
        onSuccess: () => {
            nextStep();
        }
    });
    const [expanded, setExpanded] = useState(false);
    const { nextStep } = useCreatorOnboarding();
    return (
        <div className="sticky top-16 z-50 w-full bg-primary px-3 py-2 text-white" onClick={() => setExpanded(prev => !prev)}>
            <span className="flex items-center justify-between">
                <h5 className="font-bold">#13 Konzistence je cestou k úspěchu</h5>
                <motion.div
                    animate={{
                        rotate: expanded ? 180 : 0,
                        opacity: [0.3, 1, 0.3]
                    }}
                    transition={{
                        opacity: {
                            repeat: Infinity,
                            duration: 1,
                            ease: 'easeOut'
                        }
                    }}>
                    <FaChevronCircleDown />
                </motion.div>
            </span>
            <span>
                <motion.div
                    initial={{ height: 0 }}
                    animate={{
                        height: expanded ? 'auto' : 0
                    }}
                    className="space-y-3 overflow-hidden">
                    <div className="my-3 h-px bg-secondary" />
                    <p>
                        Přidej další tématický set a pamatuj, že konzistence je klíčem k úspěchu! 🎯 Podívej se na úspěšné YouTubery – ti, kteří pravidelně tvoří obsah, si budují
                        největší publikum a vydělávají nejvíce. Stejně tak je to i tady: čím pravidelněji přidáváš nový obsah, tím víc fanoušků získáš a tím větší šanci máš na
                        splnění svých cílů! 💪📸
                    </p>
                    <BButton variant="secondary" className="flex w-full items-center gap-2" onClick={() => checkCondition()}>
                        <FaCheck />
                        Hotovo
                    </BButton>
                </motion.div>
            </span>
        </div>
    );
};

export default Step13;
