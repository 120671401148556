'use client';
import { signOut } from '@/actions/auth.actions';
import { getUserCoins, getUserSettings } from '@/actions/user.actions';
import { useTopUpOpen } from '@/stores/topup.open.store';
import { cn } from '@/lib/utils';
import { useQuery } from '@tanstack/react-query';
import { useLongPress } from '@uidotdev/usehooks';
import React from 'react';
import { LuCoins, LuLoader } from 'react-icons/lu';
import { FaDollarSign, FaEuroSign } from 'react-icons/fa';
import { getSettings } from '@/app/admin/actions/admin.actions';
import { max } from 'drizzle-orm';

const CoinsCount: React.FC<{ isCreator?: boolean; className?: string }> = ({ isCreator = false, className }) => {
    const openTopUp = useTopUpOpen(state => state.setOpen);
    const {
        data: coins,
        isLoading,
        isFetching
    } = useQuery({
        queryKey: ['coins'],
        queryFn: async () => await getUserCoins(),
        staleTime: 0,
        refetchOnWindowFocus: true
    });
    const { data: appSettings } = useQuery({
        queryKey: ['appSettings'],
        queryFn: () => getSettings(),
        staleTime: Infinity
    });
    const { data: userSettings } = useQuery({
        queryKey: ['userSettings'],
        queryFn: async () => await getUserSettings(),
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        enabled: isCreator
    });
    const longPressEvent = useLongPress(
        async () => {
            await signOut();
            location.reload();
        },
        {
            threshold: 500
        }
    );
    const formatedCoins = () => {
        switch (userSettings?.currency) {
            case 'czk':
                return Intl.NumberFormat('cs-CZ').format(coins ?? 0);
            case 'eur':
                return Intl.NumberFormat('de-DE', {
                    maximumFractionDigits: 2
                }).format((coins ?? 0) / (appSettings?.eurConversion || 1));
            case 'usd':
                return Intl.NumberFormat('en-US', {
                    maximumFractionDigits: 2
                }).format((coins ?? 0) / (appSettings?.usdConversion || 1));
            default:
                return Intl.NumberFormat('cs-CZ').format(coins ?? 0);
        }
    };
    return (
        <div
            id="coins"
            className={cn('flex items-center justify-center gap-1 text-primary', isCreator && 'text-primary', className)}
            {...longPressEvent}
            onClick={() => openTopUp(true)}>
            <span className="text-muted-foreground">{isLoading || isFetching ? <LuLoader className="animate-spin" /> : isCreator ? formatedCoins() : coins}</span>
            {isCreator ? (
                <>
                    {userSettings?.currency === 'czk' && <span className="text-muted-foreground">Kč</span>}
                    {userSettings?.currency === 'eur' && (
                        <span className="text-muted-foreground">
                            <FaEuroSign />
                        </span>
                    )}
                    {userSettings?.currency === 'usd' && (
                        <span className="text-muted-foreground">
                            <FaDollarSign />
                        </span>
                    )}
                </>
            ) : (
                <LuCoins size={16} />
            )}
        </div>
    );
};

export default CoinsCount;
