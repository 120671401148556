'use client';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import { loadFull } from 'tsparticles'; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { type Container, type ISourceOptions, MoveDirection, OutMode } from '@tsparticles/engine';
import { useTheme } from 'next-themes';

const Snow = () => {
    const { resolvedTheme } = useTheme();
    const [init, setInit] = useState(false);
    // this should be run only once per application lifetime
    useEffect(() => {
        initParticlesEngine(async engine => {
            // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
            // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
            // starting from v2 you can add only the features you need reducing the bundle size
            //await loadAll(engine);
            //await loadFull(engine);
            await loadFull(engine);
            //await loadBasic(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    const options: ISourceOptions = useMemo(
        () => ({
            fpsLimit: 120,
            particles: {
                wobble: {
                    distance: 10,
                    enable: true,
                    speed: {
                        angle: 10,
                        move: 10
                    }
                },
                color: {
                    value: '#fff'
                },
                move: {
                    direction: MoveDirection.bottom,
                    enable: true,
                    outModes: {
                        default: OutMode.out
                    },
                    random: false,
                    speed: 3,
                    straight: true,
                    path: {
                        clamp: true
                    }
                },
                number: {
                    density: {
                        enable: true
                    },
                    value: 250
                },
                opacity: {
                    value: { min: 0.1, max: 0.8 }
                },
                shape: {
                    type: 'circle'
                },
                size: {
                    value: { min: 0.5, max: 5 }
                }
            },
            detectRetina: true
        }),
        [resolvedTheme]
    );
    if (init) {
        return (
            <div className="z-[9999999999999999999999999]">
                <Particles id="tsparticles" options={options} />
            </div>
        );
    }
};

export default Snow;
