'use client';
import UploadContentV2 from '@/app/[locale]/(private)/(creator)/(components)/UploadContentV2';
import { Role, User } from '@/db/schema';
import { checkUserRole, cn, getProfilePicture } from '@/lib/utils';
import { useAppSettingsStore } from '@/stores/appSettings.store';
import { useOnlineUsers } from '@/stores/onlineUsers.store';
import { useTopUpOpen } from '@/stores/topup.open.store';
import { useQueryClient } from '@tanstack/react-query';
import { AnimatePresence } from 'motion/react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useEffect, useMemo, useState } from 'react';
import { FaExclamation } from 'react-icons/fa';
import { FiImage, FiMessageCircle, FiPlusCircle, FiVideo } from 'react-icons/fi';
import { IoChatbubbles, IoChatbubblesOutline, IoHome, IoHomeOutline, IoNotifications, IoNotificationsOutline } from 'react-icons/io5';
import AnimatedUploadButton from '../AnimatedUploadButton';
import { useChatStore } from '../RealtimeService/chat.store';
import TopUpDialog from '../TopUpDialog';
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';
import { BButton } from '../ui/button';
import { useToast } from '../ui/use-toast';
import './BottomNav.css';
import ProfileDrawer from './ProfileDrawer';
import Image from 'next/image';

const BottomNav = ({ user }: { user: User }) => {
    const { open: topUpOpen, setOpen: setTopUpOpen } = useTopUpOpen();
    const pathname = usePathname();
    const showSnow = useAppSettingsStore(s => s.showSnow);
    const { onlineUsers } = useOnlineUsers();
    const { unreadCount } = useChatStore();
    const constructMessage = (message: string) => {
        return (
            <div className="flex items-center gap-1">
                {message.split('_')[0] === 'IMG' ? <FiImage /> : message.split('_')[0] === 'VID' ? <FiVideo /> : <FiMessageCircle />}
                {message.split('_')[1]}
            </div>
        );
    };

    const links = useMemo(
        () => [
            {
                name: 'home',
                href: user.role.includes(Role.Creator) && !user.role.includes(Role.Admin) ? '/profile' : '/home',
                Icon: <IoHomeOutline size={30} />,
                ActiveIcon: <IoHome size={30} />,
                label: 'Domů'
            },
            {
                name: 'notifications',
                href: '/notifications',
                Icon: <IoNotificationsOutline size={30} />,
                ActiveIcon: <IoNotifications size={30} />,
                label: 'Notifikace'
            },
            {
                name: 'topup',
                action: !user.role.includes(Role.Creator)
                    ? () => setTopUpOpen(true)
                    : () => {
                          console.log('opening');
                          setUploadOpen(true);
                      },
                Icon: <FiPlusCircle size={30} />,
                ActiveIcon: <FiPlusCircle size={30} />,
                label: !user.role.includes(Role.Creator) ? 'Nabít Kredity' : 'Přidat Příspěvek'
            },
            {
                name: 'chats',
                href: '/supachat',
                ActiveIcon: <IoChatbubbles size={30} />,
                label: 'Chaty'
            },
            {
                name: 'profile',
                action: () => setProfileOpen(prev => !prev),
                Icon: (
                    <Avatar className="avatar h-[30px] w-[30px]">
                        <AvatarImage src={getProfilePicture(user.username, user.image) ?? ''} />
                        <AvatarFallback>
                            <img src={`https://api.dicebear.com/9.x/thumbs/svg?seed=${user.username}`} alt={user.username} />
                        </AvatarFallback>
                    </Avatar>
                ),
                label: 'Profil'
            }
        ],
        []
    );
    const [profileOpen, setProfileOpen] = useState(false);
    const [uploadOpen, setUploadOpen] = useState(false);

    useEffect(() => {
        setProfileOpen(false);
        setUploadOpen(false);
        setTopUpOpen(false);
    }, [pathname]);

    return (
        <>
            <AnimatePresence>{uploadOpen && <UploadContentV2 onClose={() => setUploadOpen(false)} />}</AnimatePresence>
            <div className={cn('fixed bottom-0 z-40 h-20 w-full', pathname.includes('/chats/') && 'hidden')}>
                <TopUpDialog isOpen={topUpOpen} onClose={open => setTopUpOpen(open)} />
                <AnimatePresence>
                    {/* {uploadOpen && <UploadContent onClose={open => setUploadOpen(open)} />} */}
                    {profileOpen && <ProfileDrawer user={user} isOpen={profileOpen} onClose={() => setProfileOpen(false)} />}
                </AnimatePresence>
                <nav
                    className="inset-x-0 z-40 flex h-20 items-center justify-around bg-background px-4 py-2"
                    style={{
                        boxShadow: '0px -3px 10px 0px hsl(0,0%,0%)'
                    }}>
                    {links.map(({ Icon, ActiveIcon, ...link }) => {
                        if (link.name === 'chats') {
                            return (
                                <Link href={link.href!} key={link.name} className="relative">
                                    {unreadCount > 0 && (
                                        <>
                                            <div className="absolute inset-0 h-full w-full animate-ping rounded-full border-2 border-red-500" />
                                            <div className="absolute inset-0 h-full w-full animate-ping rounded-full border-2 border-red-500 delay-100" />
                                            <div className="absolute -right-2 -top-2 z-50 flex size-5 items-center justify-center rounded-full bg-red-500 text-white">
                                                {unreadCount}
                                            </div>
                                        </>
                                    )}
                                    {/* <div className="absolute inset-0 h-full w-full animate-ping rounded-full border-2 border-primary delay-150" /> */}
                                    {pathname === link.href ? <IoChatbubbles size={30} className="relative z-40" /> : <IoChatbubblesOutline size={30} className="relative z-40" />}
                                    {unreadCount > 0 && (
                                        <div className="absolute -right-2 -top-2 z-50 flex size-5 items-center justify-center rounded-full bg-red-500 text-white">
                                            {unreadCount}
                                        </div>
                                    )}
                                    {/* <div className="absolute -right-5 -top-3 z-50 rounded bg-gradient-to-tr from-orange-600 to-purple-800 px-1 py-0.5">supa</div> */}
                                </Link>
                            );
                        }
                        if (link.name === 'topup') {
                            return (
                                <div key={link.name}>
                                    {checkUserRole(user, [Role.Creator]) ? (
                                        <AnimatedUploadButton key={link.name} onClick={link.action!} isUploading={false} />
                                    ) : showSnow ? (
                                        <>
                                            <div className="relative -mx-12 w-36 -translate-y-[14px] cursor-pointer" onClick={link.action}>
                                                <img src="/giftButton.webp" className="h-full w-full object-contain" alt="btn" />
                                            </div>
                                        </>
                                    ) : (
                                        <BButton
                                            id="coinsTopUp"
                                            key={link.name}
                                            disabled={checkUserRole(user, [Role.Creator])}
                                            onClick={link.action}
                                            size="icon"
                                            className="relative -mx-4 flex h-24 w-20 flex-col items-center justify-center gap-2 bg-primary text-xs">
                                            {Icon}
                                        </BButton>
                                    )}
                                </div>
                            );
                        }
                        if (link.name === 'profile') {
                            return (
                                <div className="relative" key={link.name} onClick={link.action} id="profile">
                                    <Avatar className="avatar h-[30px] w-[30px]">
                                        <AvatarImage src={getProfilePicture(user.username, user.image) ?? ''} />
                                    </Avatar>
                                    <span
                                        className={cn('absolute bottom-0 right-0 size-3 rounded-full', {
                                            'bg-red-500': !onlineUsers.some(ou => ou.userId === user.id),
                                            'bg-green-500': onlineUsers.some(ou => ou.userId === user.id)
                                        })}
                                    />
                                </div>
                            );
                        }
                        return (
                            <Link
                                id={link.name}
                                key={link.name}
                                href={link.href ?? ''}
                                onClick={link.action}
                                className="relative flex flex-col items-center justify-center gap-2 text-xs">
                                {pathname === link.href ? ActiveIcon : Icon}
                            </Link>
                        );
                    })}
                </nav>
            </div>
        </>
    );
};

export default BottomNav;
