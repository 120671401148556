import { base64ToUint8Array } from './utils';

export const registerServiceWorker = async () => {
    if ('serviceWorker' in navigator) {
        try {
            const reg = await navigator.serviceWorker.register('/service-worker.js');
            console.log('Service worker registered', reg);
            return reg;
        } catch (e) {
            console.error('Service worker registration failed', e);
        }
    } else {
        console.warn('Service worker not supported');
    }
};

export const getReadyServiceWorker = async () => {
    if (!('serviceWorker' in navigator)) {
        console.log('Service worker not supported');
        return;
    }
    return await navigator.serviceWorker.ready;
};

export async function getCurrentPushSubscription() {
    const sw = await getReadyServiceWorker();
    return sw?.pushManager?.getSubscription();
}

export async function subscribeToPushNotifications() {
    const sw = await getReadyServiceWorker();
    const sub = await sw?.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: base64ToUint8Array(process.env.NEXT_PUBLIC_WEB_PUSH_PUBLIC_KEY!)
    });
    if (sub) await sendSubscriptionToServer(sub);
    return sub;
}

export async function unsubscribeFromPushNotifications() {
    const sw = await getReadyServiceWorker();
    const sub = await sw?.pushManager.getSubscription();
    if (sub) {
        await removeSubscriptionFromServer(sub);
        return sub.unsubscribe();
    }
}

export async function isSubscribedToPushNotifications() {
    const subscription = await getCurrentPushSubscription();
    return subscription !== null;
}

export async function sendSubscriptionToServer(sub: PushSubscription) {
    console.log('Sending subscription to server', sub);
    fetch('/api/notification/register-push', {
        method: 'POST',
        body: JSON.stringify(sub),
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function removeSubscriptionFromServer(sub: PushSubscription) {
    console.log('Removing subscription from server', sub);
    fetch('/api/notification/register-push', {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        }
    });
}
