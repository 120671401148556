import { create } from 'zustand';

interface ChatState {
    unreadCount: number;
    addUnreadCount: () => void;
    clearUnreadCount: () => void;
    removeUnreadCount: () => void;
    setUnreadCount: (count: number) => void;
}

export const useChatStore = create<ChatState>(set => ({
    unreadCount: 0,
    clearUnreadCount: () => set({ unreadCount: 0 }),
    addUnreadCount: () => set(state => ({ unreadCount: Number(state.unreadCount ?? 0) + 1 })),
    removeUnreadCount: () => set(state => ({ unreadCount: Number(state.unreadCount ?? 0) - 1 })),
    setUnreadCount: (count: number) => set({ unreadCount: count })
}));
