'use client';
import { increaseCreatorOnboardingStep } from '@/actions/creator.actions';
import { Role, User } from '@/db/schema';
import { useCreatorOnboarding } from '@/stores/creator-onboarding.store';
import { useMutation } from '@tanstack/react-query';
import { AnimatePresence, motion } from 'motion/react';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import PortalComponent from '../shared/PortalComponent';
import InstallApp from './InstallApp';
import Step0 from './Step0';
import Step1 from './Step1';
import Step10 from './Step10.info';
import Step11 from './Step11.info';
import Step12 from './Step12.info';
import Step13 from './Step13.info';
import Step14 from './Step14.info';
import Step15 from './Step15.info';
import Step16 from './Step16.info';
import Step17 from './Step17.info';
import Step18 from './Step18.info';
import Step19 from './Step19.info';
import Step2 from './Step2';
import Step20 from './Step20.info';
import Step3 from './Step3';
import Step4 from './Step4.interactive';
import Step5 from './Step5';
import Step6 from './Step6';
import Step7 from './Step7.interactive';
import Step8 from './Step8.interactive';
import Step9 from './Step9.info';

const CreatorOnboarding = ({ user }: { user: User }) => {
    const router = useRouter();
    const [showInstallApp, setShowInstallApp] = useState(false);
    const { nextStep, minimized, step, setStep } = useCreatorOnboarding();
    const { mutate: increaseOnboardingStep } = useMutation({
        mutationFn: () => increaseCreatorOnboardingStep(),
        onSuccess: () => {
            nextStep();
        }
    });
    useEffect(() => {
        if (user.onboardingCompleted) return;
        if (!user.onboardingCompleted) {
            router.push('/home');
            setStep(user.onboardingStep ?? 0);
        }
        if (user.onboardingStep === 2 && user.monthlyGoal > 0) {
            setShowInstallApp(true);
        }
    }, [user]);
    const isInfoStep = () => {
        return step > 8;
    };
    const isInteractiveStep = () => {
        switch (step) {
            case 4:
            case 7:
            case 8:
                return true;
            default:
                return false;
        }
    };
    if (user.onboardingCompleted || user.role.includes(Role.User) || user.role.includes(Role.Admin)) return <></>;
    return (
        <>
            {isInfoStep() === false ? (
                <PortalComponent>
                    <AnimatePresence mode="wait" initial={false}>
                        {!isInteractiveStep() && !minimized && (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.5 }}
                                className="fixed inset-0 z-ultr flex items-center justify-center bg-background/60 px-10 backdrop-blur-lg">
                                <AnimatePresence mode="wait" initial={false}>
                                    {step === 0 && <Step0 onNext={() => increaseOnboardingStep()} />}
                                    {step === 1 && <Step1 onNext={() => increaseOnboardingStep()} />}
                                    {step === 2 && !showInstallApp && <Step2 onNext={() => setShowInstallApp(true)} userId={user.id} />}
                                    {showInstallApp && step === 2 && <InstallApp />}
                                    {step === 3 && <Step3 onNext={() => increaseOnboardingStep()} userId={user.id} />}
                                    {step === 5 && <Step5 onNext={() => increaseOnboardingStep()} userId={user.id} />}
                                    {step === 6 && <Step6 onNext={() => increaseOnboardingStep()} userId={user.id} />}
                                </AnimatePresence>
                            </motion.div>
                        )}
                    </AnimatePresence>
                    {isInteractiveStep() && !minimized && (
                        <div className="fixed inset-0 z-ultra flex items-center justify-center px-10">
                            {step === 4 && <Step4 onNext={() => increaseOnboardingStep()} />}
                            {step === 7 && <Step7 onNext={() => increaseOnboardingStep()} />}
                            {step === 8 && <Step8 onNext={() => increaseOnboardingStep()} />}
                        </div>
                    )}
                </PortalComponent>
            ) : (
                <>
                    {step === 9 && <Step9 />}
                    {step === 10 && <Step10 />}
                    {step === 11 && <Step11 />}
                    {step === 12 && <Step12 />}
                    {step === 13 && <Step13 />}
                    {step === 14 && <Step14 />}
                    {step === 15 && <Step15 />}
                    {step === 16 && <Step16 />}
                    {step === 17 && <Step17 />}
                    {step === 18 && <Step18 />}
                    {step === 19 && <Step19 />}
                    {step === 20 && <Step20 />}
                </>
            )}
        </>
    );
};

export default CreatorOnboarding;
