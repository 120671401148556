'use client';

import { useLockBodyScroll } from '@uidotdev/usehooks';
import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';

const PortalComponent = ({ children }: { children: React.ReactNode }) => {
    const [mounted, setMounted] = useState(false);
    useLockBodyScroll();

    useEffect(() => {
        setMounted(true);
        return () => setMounted(false);
    }, []);

    if (!mounted) return null;

    const portalRoot = document.getElementById('portal-root');
    if (!portalRoot) return null;

    return createPortal(children, portalRoot);
};

export default PortalComponent;
