import { create } from 'zustand';
import { differenceInMinutes } from 'date-fns';
import { getOnlineUsers } from '@/actions/user.actions';
import { OnlineUser } from '@/db/schema';

type OnlineState = {
    onlineUsers: OnlineUser[];
    isUserOnline: (id: string) => boolean;
    setOnlineUsers: (users: OnlineUser[]) => void;
    initOnlineUsers: () => Promise<void>;
    getUserStatusColor: (id: string) => 'green' | 'orange' | 'red';
    updateOnlineUser: (id: string, lastSeen: string, isOnline: boolean) => void;
    getOnlineUser: (id: string) => OnlineUser | null;
};

export const useOnlineUsers = create<OnlineState>((set, get) => ({
    onlineUsers: [],
    isUserOnline: (id: string) => {
        return get().onlineUsers.some(user => user.userId === id);
    },
    getUserStatusColor: (id: string) => {
        const { onlineUsers } = get();
        const user = onlineUsers.find(u => u.userId === id);
        if (user) {
            const difference = differenceInMinutes(new Date(), user.lastSeen);
            if (difference <= 5) {
                return 'green';
            } else if (difference < 60) {
                return 'orange';
            }
        }
        return 'red';
    },
    setOnlineUsers: users => set({ onlineUsers: users }),
    initOnlineUsers: async () => {
        const users = await getOnlineUsers();
        set({ onlineUsers: users });
    },
    updateOnlineUser: (id, lastSeen, isOnline) => {
        set(state => {
            const users = state.onlineUsers.map(u => (u.userId === id ? { ...u, lastSeen, isOnline } : u));
            return { onlineUsers: users };
        });
    },
    getOnlineUser: (id: string) => {
        return get().onlineUsers.find(u => u.userId === id) ?? null;
    }
}));
