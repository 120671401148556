import { create } from 'zustand';

type CoinsState = {
    coins: number;
    setCoins: (coins: number) => void;
    addCoins: (coins: number) => void;
    removeCoins: (coins: number) => void;
};

export const useCoins = create<CoinsState>()(set => ({
    coins: 0,
    setCoins: newCoins => set(() => ({ coins: newCoins })),
    addCoins: coins => set(state => ({ coins: state.coins + coins })),
    removeCoins: coins => set(state => ({ coins: state.coins - coins }))
}));
