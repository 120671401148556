'use client';
import { getOnlineUsers, getUserById, toggleOnlineStatus } from '@/actions/user.actions';
import { getUnreadMessagesCount } from '@/app/[locale]/(private)/supachat/actions/chat.actions';
import { Message } from '@/db/chat.schema';
import { OnlineUser } from '@/db/schema';
import { usePathname } from '@/i18n/routing';
import { supabaseClient } from '@/lib/supabase/client';
import { useOnlineUsers } from '@/stores/onlineUsers.store';
import { useUser } from '@/stores/user.store';
import { RealtimePostgresChangesPayload } from '@supabase/supabase-js';
import { useMutation, useQuery } from '@tanstack/react-query';
import { differenceInMinutes } from 'date-fns';
import React, { useEffect } from 'react';
import { useToast } from '../ui/use-toast';
import { useChatStore } from './chat.store';

const RealtimeService = ({ children, chatIds }: { children: React.ReactNode; chatIds: string[] }) => {
    const user = useUser();
    const pathname = usePathname();
    const [lastActivity, setLastActivity] = React.useState<Date>(new Date());
    const [isActive, setIsActive] = React.useState(true);
    const { updateOnlineUser, onlineUsers, setOnlineUsers } = useOnlineUsers();
    const { toast } = useToast();
    const { addUnreadCount, removeUnreadCount, setUnreadCount } = useChatStore();

    const { data: unreadCount } = useQuery({
        queryKey: ['unreadCount', user?.id],
        queryFn: () => getUnreadMessagesCount()
    });

    const { mutate: toggleOnline } = useMutation({
        mutationFn: (val: boolean) => toggleOnlineStatus(val)
    });

    const handleNewMessage = async (payload: Message) => {
        console.log(payload);
        const sender = await getUserById(payload.userId);
        if (payload.userId === user?.id) return;
        toast({
            title: `Nová zpráva`,
            description: `${sender?.username}: ${payload.text}`
        });
        addUnreadCount();
    };

    useEffect(() => {
        const initOnlineUsers = async () => {
            const users = await getOnlineUsers();
            setOnlineUsers(users);
        };
        initOnlineUsers();
        toggleOnline(true);
    }, []);

    useEffect(() => {
        if (unreadCount) {
            setUnreadCount(Number(unreadCount));
        }
    }, [unreadCount]);

    useEffect(() => {
        const handleActivity = () => {
            setLastActivity(new Date());
            if (!isActive) {
                setIsActive(true);
                toggleOnline(true);
            }
        };
        const handleVisibilityChange = () => {
            if (document.hidden) {
                setIsActive(false);
                toggleOnline(false);
            } else {
                setIsActive(true);
                toggleOnline(true);
            }
        };
        // Set up activity listeners
        const events = ['mousedown', 'keydown', 'mousemove', 'touchstart'];
        events.forEach(event => window.addEventListener(event, handleActivity));

        // check minimaized app
        document.addEventListener('visibilitychange', handleVisibilityChange);

        const activityCheck = setInterval(
            () => {
                const now = new Date();
                const inactiveTime = differenceInMinutes(now, lastActivity);

                // If inactive for more than 1 minute, set as away
                if (inactiveTime >= 1 && isActive) {
                    setIsActive(false);
                    toggleOnline(false);
                }
            },
            1 * 60 * 1000
        );
        return () => {
            events.forEach(event => window.removeEventListener(event, handleActivity));
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            clearInterval(activityCheck);
        };
    }, [isActive]);
    const messagesChannel = supabaseClient.channel('unreadMessages');
    const onlineUsersChannel = supabaseClient.channel('onlineUsers');

    useEffect(() => {
        messagesChannel
            .on('postgres_changes', { event: '*', schema: 'public', table: 'messages' }, p => {
                const payload = p.new as Message;
                if (!chatIds.some(id => id === payload.chatId)) {
                    return;
                }
                if (p.eventType === 'INSERT' && pathname.includes(`${p.new.chatId}`)) {
                    return;
                } else if (p.eventType === 'INSERT') {
                    const payload = p.new as Message;
                    handleNewMessage(payload);
                } else if ((p.eventType === 'UPDATE' && p.new.readBy.includes(user.id)) || p.eventType === 'DELETE') {
                    removeUnreadCount();
                }
            })
            .subscribe();
        onlineUsersChannel
            .on('postgres_changes', { event: '*', schema: 'public', table: 'online_users' }, (p: RealtimePostgresChangesPayload<OnlineUser>) => {
                const payload = p.new as { last_Seen: any; is_online: boolean; userId: string };
                updateOnlineUser(payload.userId, payload.last_Seen, payload.is_online);
            })
            .subscribe();
        return () => {
            supabaseClient.removeChannel(messagesChannel);
            supabaseClient.removeChannel(onlineUsersChannel);
        };
    }, [pathname]);

    return (
        <div>
            {/* <div className="fixed bottom-20 right-5 z-ultr rounded bg-red-600 px-4 py-1 text-white">
                {JSON.stringify(chatIds?.find(id => id === '3542fd7a-88c9-48db-8115-915902abc6c1'))}
            </div> */}
            {children}
        </div>
    );
};

export default RealtimeService;
