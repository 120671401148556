import { create } from 'zustand';

type TopUpStore = {
    open: boolean;
    setOpen: (open: boolean) => void;
};

export const useTopUpOpen = create<TopUpStore>(set => ({
    open: false,
    setOpen: open => set(() => ({ open }))
}));
