'use client';
import React, { useState } from 'react';
import { FaArrowRight, FaChevronCircleDown, FaChevronCircleUp } from 'react-icons/fa';
import { motion } from 'motion/react';
import { BButton } from '../ui/button';
import { FaCheck } from 'react-icons/fa6';
import { useLockBodyScroll } from '@uidotdev/usehooks';
import { useCreatorOnboarding } from '@/stores/creator-onboarding.store';
import { increaseCreatorOnboardingStep } from '@/actions/creator.actions';
import { useMutation } from '@tanstack/react-query';

const Step12 = () => {
    const { mutate: increaseOnboardingStep } = useMutation({
        mutationFn: () => increaseCreatorOnboardingStep(),
        onSuccess: () => {
            nextStep();
        }
    });
    const [expanded, setExpanded] = useState(false);
    const { nextStep } = useCreatorOnboarding();
    return (
        <div className="sticky top-16 z-50 w-full bg-primary px-3 py-2 text-white" onClick={() => setExpanded(prev => !prev)}>
            <span className="flex items-center justify-between">
                <h5 className="font-bold">#12 Vytvoř si rutinu</h5>
                <motion.div
                    animate={{
                        rotate: expanded ? 180 : 0,
                        opacity: [0.3, 1, 0.3]
                    }}
                    transition={{
                        opacity: {
                            repeat: Infinity,
                            duration: 1,
                            ease: 'easeOut'
                        }
                    }}>
                    <FaChevronCircleDown />
                </motion.div>
            </span>
            <span>
                <motion.div
                    initial={{ height: 0 }}
                    animate={{
                        height: expanded ? 'auto' : 0
                    }}
                    className="space-y-3 overflow-hidden">
                    <div className="my-3 h-px bg-secondary" />
                    <p>
                        Vytvoř si plán pravidelného tvoření a sdílení obsahu. Zvol si, co ti vyhovuje nejvíc – můžeš si na focení a tvorbu vynahradit víkend, nebo se tomu věnovat
                        každý den jen pár minut. Měj vždy nějaký obsah v záloze pro situace, kdy na tvorbu nebudeš mít čas nebo energii. Tím zajistíš, že budeš moct pravidelně
                        sdílet obsah a udržovat své fanoušky aktivní a zapojené.
                    </p>
                    <BButton variant="secondary" className="flex w-full items-center gap-2" onClick={() => increaseOnboardingStep()}>
                        Další
                        <FaArrowRight />
                    </BButton>
                </motion.div>
            </span>
        </div>
    );
};

export default Step12;
