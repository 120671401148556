'use client';
import { motion } from 'motion/react';
import { FaArrowRight } from 'react-icons/fa';
import AkLogo from '../AkLogo';
import { BButton } from '../ui/button';

const Step6 = ({ onNext, userId }: { onNext: () => void; userId: string }) => {
    return (
        <motion.div initial={{ opacity: 0, x: 500 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -500 }} transition={{ duration: 0.5 }}>
            <div className="flex w-full max-w-3xl flex-col items-center gap-4">
                <div className="">
                    <AkLogo size={250} />
                </div>
                <h1 className="text-2xl font-bold">#6 Najdi si svůj osobitý styl</h1>
                <p className="w-full max-w-xl text-justify">
                    Rozhodni se, jaký typ obsahu chceš sdílet – může to být fitness, fashion, glamour, lifestyle nebo cokoli, co tě vystihuje. Důležité je, aby tvůj obsah měl
                    autenticitu a byl v souladu s tvým osobním stylem.
                </p>
                <p>
                    Snaž se odlišit tím, že nabídneš něco, co tě činí výjimečnou – ať už jde o specifický způsob komunikace, unikátní fotky, nebo tematiku, která tě baví a
                    naplňuje.
                </p>
                <BButton
                    size="lg"
                    onClick={() => {
                        onNext();
                    }}
                    className="flex items-center gap-2">
                    Další krok
                    <FaArrowRight />
                </BButton>
            </div>
        </motion.div>
    );
};

export default Step6;
