'use client';
import React from 'react';
import PortalComponent from '../shared/PortalComponent';
import AkLogo from '../AkLogo';
import { BButton } from '../ui/button';
import { FaArrowRight, FaPlayCircle } from 'react-icons/fa';
import { motion } from 'motion/react';

const Step1 = ({ onNext }: { onNext: () => void }) => {
    return (
        <motion.div initial={{ opacity: 0, x: 500 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -500 }} transition={{ duration: 0.5 }}>
            <div className="flex w-full max-w-3xl flex-col items-center gap-4">
                <div className="">
                    <AkLogo size={250} />
                </div>
                <h1 className="text-2xl font-bold">#1 Proč se stát AK GIRL?</h1>
                <p className="w-full max-w-xl text-justify">
                    Být AK GIRL není jen prestiž – je to zábava, svoboda a šance vydělávat tím, co tě baví! Jsi pánem svého času, tvoříš obsah podle sebe, sdílíš svůj svět s lidmi,
                    kteří tě sledují, a buduješ komunitu věrných fanoušků. Tvůj úspěch máš plně ve svých rukou – záleží jen na tvé aktivitě!
                </p>
                <BButton size="lg" onClick={onNext} className="flex items-center gap-2">
                    Další krok
                    <FaArrowRight />
                </BButton>
            </div>
        </motion.div>
    );
};

export default Step1;
