'use client';
import { checkCreatorOnboardingCondition } from '@/actions/creator.actions';
import { useCreatorOnboarding } from '@/stores/creator-onboarding.store';
import { useMutation } from '@tanstack/react-query';
import { motion } from 'motion/react';
import { useState } from 'react';
import { FaCheck, FaChevronCircleDown } from 'react-icons/fa';
import { BButton } from '../ui/button';
import { useToast } from '../ui/use-toast';

const CONDITION_ASSETS = 15;

const Step18 = () => {
    const { toast } = useToast();
    const { mutate: checkCondition } = useMutation({
        mutationFn: () => checkCreatorOnboardingCondition(CONDITION_ASSETS),
        onError: () => {
            toast({
                title: 'Podmínka není splněna',
                description: `Nahráj alespoň ${CONDITION_ASSETS}x obsah a zkuste to znovu.`,
                variant: 'destructive'
            });
        },
        onSuccess: () => {
            nextStep();
        }
    });
    const [expanded, setExpanded] = useState(false);
    const { nextStep } = useCreatorOnboarding();
    return (
        <div className="sticky top-16 z-50 w-full bg-primary px-3 py-2 text-white" onClick={() => setExpanded(prev => !prev)}>
            <span className="flex items-center justify-between">
                <h5 className="font-bold">#18 Rozšířený tématický set</h5>
                <motion.div
                    animate={{
                        rotate: expanded ? 180 : 0,
                        opacity: [0.3, 1, 0.3]
                    }}
                    transition={{
                        opacity: {
                            repeat: Infinity,
                            duration: 1,
                            ease: 'easeOut'
                        }
                    }}>
                    <FaChevronCircleDown />
                </motion.div>
            </span>
            <span>
                <motion.div
                    initial={{ height: 0 }}
                    animate={{
                        height: expanded ? 'auto' : 0
                    }}
                    className="space-y-3 overflow-hidden">
                    <div className="my-3 h-px bg-secondary" />
                    <p>
                        Pokračuj v posouvání svého obsahu na další úroveň. Nahraj rozšířený tematický set 6 fotek. 3 fotky nabídni zdarma pro všechny na sociálních sítích, aby si
                        získala více sledujících, a všech 6, z toho 3 exkluzivní fotky sdílej pouze na AK GIRLS.
                    </p>
                    <p>Tímto způsobem ukážeš hodnotu svého VIP obsahu a motivuješ další fanoušky k připojení.</p>
                    <BButton variant="secondary" className="flex w-full items-center gap-2" onClick={() => checkCondition()}>
                        <FaCheck />
                        Hotovo
                    </BButton>
                </motion.div>
            </span>
        </div>
    );
};

export default Step18;
