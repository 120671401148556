'use client';
import React, { useState } from 'react';
import PortalComponent from '../shared/PortalComponent';
import AkLogo from '../AkLogo';
import { BButton } from '../ui/button';
import { FaArrowRight } from 'react-icons/fa';
import { Input, InputAdorment } from '../ui/input';
import { Label } from '../ui/label';
import { useMutation } from '@tanstack/react-query';
import { updateCreatorMonthlyGoal } from '@/actions/creator.actions';
import { Loader2 } from 'lucide-react';
import { motion } from 'motion/react';

const Step2 = ({ onNext, userId }: { onNext: () => void; userId: string }) => {
    const [monthlyGoal, setMonthlyGoal] = useState(0);
    const { mutate: setGoal, isPending } = useMutation({
        mutationFn: () => updateCreatorMonthlyGoal(userId, monthlyGoal),
        onSuccess: () => {
            onNext();
        }
    });
    return (
        <motion.div initial={{ opacity: 0, x: 500 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -500 }} transition={{ duration: 0.5 }}>
            <div className="flex w-full max-w-3xl flex-col items-center gap-4">
                <div className="">
                    <AkLogo size={250} />
                </div>
                <h1 className="text-2xl font-bold">#2 Stanov si svůj cíl</h1>
                <p className="w-full max-w-xl text-justify">
                    Kolik by si chtěla měsíčně vydělávat? Urči si jasnou motivaci, která tě povede k dosažení úspěchu! 💸 Stanovení konkrétní částky ti pomůže lépe plánovat své
                    aktivity a směřovat k dosažení svého cíle.
                </p>
                <div className="space-y-1">
                    <Label>Kolik bys chtěla měsíčně vydělávat?</Label>
                    <InputAdorment placeholder="10.000" adornment={<span>Kč</span>} className="w-fit" onChange={e => setMonthlyGoal(Number(e.target.value))} />
                </div>
                <BButton
                    size="lg"
                    disabled={!monthlyGoal || monthlyGoal === 0}
                    onClick={() => {
                        setGoal();
                    }}
                    className="flex items-center gap-2">
                    {isPending ? <Loader2 className="animate-spin" /> : 'Další krok'}
                    <FaArrowRight />
                </BButton>
            </div>
        </motion.div>
    );
};

export default Step2;
