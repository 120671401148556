'use client';

import { platform } from 'os';
import React, { FC, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { FaApple } from 'react-icons/fa';
import { FiX } from 'react-icons/fi';
import { MdAndroid } from 'react-icons/md';
import PortalComponent from './PortalComponent';

interface DetectionResult {
    isPWA: boolean;
    platform: 'iOS' | 'Android' | 'Desktop' | 'Unknown';
    browser: 'Chrome' | 'Safari' | 'Other';
}

function detectEnvironment(): DetectionResult {
    if (typeof window === 'undefined') {
        return { isPWA: false, platform: 'Unknown', browser: 'Other' };
    }

    const userAgent = window.navigator.userAgent.toLowerCase();

    // Detekce PWA
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    const isFullscreen = window.matchMedia('(display-mode: fullscreen)').matches;
    const isIOSPWA = 'standalone' in window.navigator && (window.navigator as any).standalone;
    const isPWA = isStandalone || isFullscreen || isIOSPWA;

    // Detekce platformy
    let platform: DetectionResult['platform'] = 'Unknown';
    if (/iphone|ipad|ipod/.test(userAgent)) {
        platform = 'iOS';
    } else if (/android/.test(userAgent)) {
        platform = 'Android';
    } else if (/win|mac|linux/.test(userAgent)) {
        platform = 'Desktop';
    }

    // Detekce prohlížeče (pouze Chrome a Safari)
    let browser: DetectionResult['browser'] = 'Other';
    if (/chrome/.test(userAgent) && !/edg|opr/.test(userAgent)) {
        browser = 'Chrome';
    } else if (/safari/.test(userAgent) && !/chrome|edg|opr/.test(userAgent)) {
        browser = 'Safari';
    }

    return { isPWA, platform, browser };
}

function isAppPWA(): boolean {
    if (typeof window !== 'undefined') {
        // Check if the app is running in standalone mode (PWA)
        const isStandalone = window.matchMedia('(display-mode: standalone)').matches;

        // Check if the app is running in fullscreen mode (another indicator of PWA)
        const isFullscreen = window.matchMedia('(display-mode: fullscreen)').matches;

        // Check for iPad/iPhone in standalone mode (homescreen)
        const isIOSPWA = 'standalone' in window.navigator && (window.navigator as any).standalone;

        return isStandalone || isFullscreen || isIOSPWA;
    }
    return false;
}

type PwaProps = {
    username: string;
};
const PwaBanner: FC<PwaProps> = ({ username }) => {
    const [tutorialOpen, setTutorialOpen] = useState(false);
    const [env, setEnv] = useState<DetectionResult>({
        isPWA: false,
        platform: 'Unknown',
        browser: 'Other'
    });
    useEffect(() => {
        setEnv(detectEnvironment());
    }, []);

    if (env.isPWA) return null;

    const tutorialFileName = () => {
        const platform = env.platform;
        const browser = env.browser;
        const filename = `${platform.toLowerCase()}-tutorial`;

        return filename;
    };

    if (env.platform === 'Desktop') return <DesktopBanner username={username} />;

    return (
        <>
            {tutorialOpen && <VideoPopover onClose={() => setTutorialOpen(false)} videoUrl={`/tutorial/${tutorialFileName()}.mp4`} />}
            <div className="fixed inset-x-0 top-16 z-ultr bg-red-500 py-2 pt-3 text-center tracking-wider text-white" onClick={() => setTutorialOpen(true)}>
                <p>
                    Ahoj <strong>{username}</strong>, všimli jsme si že nemáš nainstalovanou aplikaci <strong>AK GIRLS</strong>.
                </p>
                <p>Pro návod, klikni zde.</p>
            </div>
        </>
    );
};

const DesktopBanner = ({ username }: { username: string }) => {
    const [tutorialOpen, setTutorialOpen] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [tutorialType, setTutorialType] = useState('android');

    useEffect(() => {
        const bannerHidden = localStorage.getItem('pwaBannerHidden');
        if (bannerHidden) {
            setIsVisible(false);
        }
    }, []);
    if (!isVisible) return null;
    return (
        <>
            {tutorialOpen && tutorialType && <VideoPopover onClose={() => setTutorialOpen(false)} videoUrl={`/tutorial/${tutorialType}-tutorial.mp4`} />}
            <div className="fixed inset-x-0 top-16 z-ultr bg-red-500 py-2 pt-3 text-center tracking-wider text-white">
                <button
                    className="absolute right-2 top-2 text-white"
                    onClick={() => {
                        setIsVisible(false);
                        localStorage.setItem('pwaBannerHidden', 'true');
                    }}>
                    <FiX className="size-6" />
                </button>
                <p>
                    Ahoj <strong>{username}</strong>, všimli jsme si že nemáš nainstalovanou aplikaci <strong>AK GIRLS</strong>.
                </p>
                <div className="mt-2 flex items-center justify-center gap-5">
                    <span
                        className="flex cursor-pointer items-center gap-2"
                        onClick={() => {
                            setTutorialType('android');
                            setTutorialOpen(true);
                        }}>
                        <MdAndroid className="size-6" />
                        Tutorial Android
                    </span>
                    <span
                        className="flex cursor-pointer items-center gap-2"
                        onClick={() => {
                            setTutorialType('ios');
                            setTutorialOpen(true);
                        }}>
                        <FaApple className="size-6" />
                        Tutorial iOS
                    </span>
                </div>
            </div>
        </>
    );
};

export const VideoPopover = ({ onClose, videoUrl }: { onClose: () => void; videoUrl?: string }) => {
    const [videoEnded, setVideoEnded] = useState(false);

    const handleVideoEnd = () => {
        setVideoEnded(true);
    };
    return (
        <PortalComponent>
            <div className="fixed inset-0 z-ultr flex bg-background">
                {videoEnded && (
                    <div className="absolute right-5 top-5 z-50 aspect-square bg-primary p-1">
                        <FiX className="size-8" onClick={onClose} />
                    </div>
                )}
                <video src={videoUrl} controls autoPlay playsInline className="h-full w-full" onEnded={handleVideoEnd}></video>
            </div>
        </PortalComponent>
    );
};

export default PwaBanner;
