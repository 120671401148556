import { create } from 'zustand';

interface UploadState {
    isUploading: boolean;
    uploadingQueueSize: number;
    addToQueue: () => void;
    removeFromQueue: () => void;
    setIsUploading: (isUploading: boolean) => void;
    uploadingType: 'image' | 'video';
    setUploadType: (type: 'image' | 'video') => void;
    reset: () => void;
}

export const useUploadStore = create<UploadState>(set => ({
    isUploading: false,
    uploadingQueueSize: 0,
    addToQueue: () => set(state => ({ uploadingQueueSize: state.uploadingQueueSize + 1 })),
    removeFromQueue: () => set(state => ({ uploadingQueueSize: Math.max(0, state.uploadingQueueSize - 1) })),
    uploadingType: 'image',
    setUploadType: type => set({ uploadingType: type }),
    setIsUploading: isUploading => set({ isUploading }),
    reset: () => set({ isUploading: false, uploadingQueueSize: 0 })
}));
