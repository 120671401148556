'use client';
import { Switch } from '@/components/ui/switch';
import { isSubscribedToPushNotifications, registerServiceWorker, subscribeToPushNotifications, unsubscribeFromPushNotifications } from '@/lib/notifications';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslations } from 'next-intl';
import React, { useEffect } from 'react';
import { toast } from 'sonner';

const SubscribeToNotifications = () => {
    const queryClient = useQueryClient();
    const t = useTranslations();
    const [isSubscribed, setIsSubscribed] = React.useState(false);

    useEffect(() => {
        isSubscribedToPushNotifications().then(res => {
            setIsSubscribed(res ?? false);
        });
        async function setupServiceWorker() {
            try {
                await registerServiceWorker();
                console.log('Service worker registered');
            } catch (error) {
                console.error('Service worker registration failed', error);
            }
        }
        setupServiceWorker();
    }, []);
    const handleNotification = async (checked: boolean) => {
        if (checked) {
            const result = await Notification.requestPermission();
            const r = Notification.permission;
            if (result === 'granted' || r === 'granted') {
                await subscribeToPushNotifications();
                setIsSubscribed(true);
                toast.success(t('toast.notifications.success.title'), {
                    description: t('toast.notifications.success.description')
                });
                queryClient.invalidateQueries({ queryKey: ['notificationEnabled'] });
            } else {
                toast.error(t('toast.notifications.error.title'), {
                    description: t('toast.notifications.error.description')
                });
                return;
            }
        } else {
            await unsubscribeFromPushNotifications();
            setIsSubscribed(false);
        }
    };

    return (
        <span className="flex items-center justify-between">
            <p className={isSubscribed ? 'font-bold' : 'font-normal'}>{t('settings.notifications')}</p>
            <Switch checked={isSubscribed} onCheckedChange={handleNotification} />
        </span>
    );
};

export default SubscribeToNotifications;
