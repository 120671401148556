'use client';
import React, { useState } from 'react';
import PortalComponent from '../shared/PortalComponent';
import AkLogo from '../AkLogo';
import { BButton } from '../ui/button';
import { FaArrowRight } from 'react-icons/fa';
import { Input, InputAdorment } from '../ui/input';
import { Label } from '../ui/label';
import { useMutation } from '@tanstack/react-query';
import { updateCreatorMonthlyGoal } from '@/actions/creator.actions';
import { Loader2 } from 'lucide-react';
import { motion } from 'motion/react';

const Step3 = ({ onNext, userId }: { onNext: () => void; userId: string }) => {
    return (
        <motion.div initial={{ opacity: 0, x: 500 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -500 }} transition={{ duration: 0.5 }}>
            <div className="flex w-full max-w-3xl flex-col items-center gap-4">
                <div className="">
                    <AkLogo size={250} />
                </div>
                <h1 className="text-2xl font-bold">#3 Začátky jsou nejtěžší</h1>
                <p className="w-full max-w-xl text-justify">
                    Každý nový začátek je výzva, ale neboj – zvládneme to společně. Teď když máme stanovený cíl, pojďme si říct víc o tom, co práce na AK GIRLS obnáší. Práce AK
                    GIRL zahrnuje pravidelné sdílení obsahu, komunikaci s fanoušky a rozšiřování své komunity.
                </p>
                <BButton
                    size="lg"
                    onClick={() => {
                        onNext();
                    }}
                    className="flex items-center gap-2">
                    Další krok
                    <FaArrowRight />
                </BButton>
            </div>
        </motion.div>
    );
};

export default Step3;
