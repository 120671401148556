'use client';
import { motion } from 'motion/react';
import { FaArrowRight, FaStar } from 'react-icons/fa';
import AkLogo from '../AkLogo';
import { BButton } from '../ui/button';

const Step5 = ({ onNext, userId }: { onNext: () => void; userId: string }) => {
    return (
        <motion.div initial={{ opacity: 0, x: 500 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -500 }} transition={{ duration: 0.5 }}>
            <div className="flex w-full max-w-3xl flex-col items-center gap-4">
                <div className="">
                    <AkLogo size={250} />
                </div>
                <div className="flex gap-3 rounded-full border px-4 py-2">
                    <FaStar className="text-yellow-300" />
                    <FaStar className="text-muted-foreground" />
                    <FaStar className="text-muted-foreground" />
                    <FaStar className="text-muted-foreground" />
                    <FaStar className="text-muted-foreground" />
                </div>
                <h1 className="text-2xl font-bold">#5 Získej svoji první star</h1>
                <p className="w-full max-w-xl text-justify">
                    Pokud se chceš posunout vpřed a dosáhnout svého cíle, tento krok je pro tebe klíčový. Dokončením všech 20 bodů tohoto návodu si nejen osvojíš všechny základní
                    kroky pro úspěch, ale také získáš svoji první star v AK rank systému. Tato hvězda je symbolem tvého pokroku a odměnou za tvou aktivitu a nasazení.{' '}
                </p>
                <BButton
                    size="lg"
                    onClick={() => {
                        onNext();
                    }}
                    className="flex items-center gap-2">
                    Další krok
                    <FaArrowRight />
                </BButton>
            </div>
        </motion.div>
    );
};

export default Step5;
