'use client';
import React, { useEffect, useState } from 'react';
import { motion } from 'motion/react';
import { Separator } from '@radix-ui/react-select';
import { BButton } from '../ui/button';
import { FaArrowRight } from 'react-icons/fa';
const Step4 = ({ onNext }: { onNext: () => void }) => {
    const [highlightedElement, setHighlightedElement] = useState<HTMLElement | null>(null);
    useEffect(() => {
        setHighlightedElement(document.getElementById('creatorRank') as HTMLElement);
    }, []);
    return (
        <div className="fixed inset-0 z-ultr flex items-center justify-center">
            {highlightedElement && (
                <motion.div
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{
                        opacity: 1,
                        scale: 1,
                        top: highlightedElement.getBoundingClientRect().top - 10,
                        left: highlightedElement.getBoundingClientRect().left - 10,
                        width: highlightedElement.offsetWidth + 20,
                        height: highlightedElement.offsetHeight + 20
                    }}
                    exit={{ opacity: 0, scale: 0.9 }}
                    transition={{ duration: 0.3 }}
                    className="absolute rounded-md border border-white shadow-[0_0_0_9999px_rgba(0,0,0,0.9)]"
                />
            )}
            <div className="z-10 flex w-full max-w-3xl flex-col items-center justify-center gap-4 px-10 text-white">
                <h1 className="text-2xl font-bold">#4 Seznam se s AK rank systémem</h1>
                <div className="h-px w-full bg-foreground/20" />
                <p className="text-sm">
                    AK Rank systém hodnotí AK GIRLS od 1 star až po 5 stars na základě jejich aktivity, účasti na eventech, interakce s fanoušky a celkového výdělku.
                </p>
                <p className="text-sm">
                    Vyšší rank ti otevírá dveře k mnoha příležitostem - spolupráci s renomovanými značkami, exkluzivním promo akcím, finančním bonusům a větší prestiž nejen v očích
                    sledujících, ale i potenciálních reklamních partnerů.
                </p>
                <BButton size="lg" onClick={onNext} className="flex items-center gap-2">
                    Další
                    <FaArrowRight />
                </BButton>
            </div>
        </div>
    );
};

export default Step4;
